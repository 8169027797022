import { useCallback, useEffect, useState, useRef, MouseEvent } from 'react';
import { useSubscription } from '@apollo/client';
import useSound from 'use-sound';
import i18n from 'i18next';

import SUBSCRIBE_STORIES from '../../graphql/subscriptions/subscribeStories';
import { canUseDom, REGEX_TITLE_COUNTER } from '../../utils';
import { TNotification } from './types';

export function useNotifications() {
  const notificationsRef = useRef(null);
  const [play] = useSound<string>('/sounds/ding.mp3');
  const [counter, setCounter] = useState<number>(0);
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const { data } = useSubscription(SUBSCRIBE_STORIES, {
    variables: {
      language: i18n.language,
    },
  });

  const onOpen = useCallback((): void => {
    setCounter(0);
  }, []);

  const onClick = useCallback((e: MouseEvent<HTMLElement>): void => {
    const hash = e.currentTarget.getAttribute('data-hash');

    setNotifications((prevState): TNotification[] => {
      const index = prevState.findIndex((item) => item.hash === hash);
      prevState[index] = { ...prevState[index], isNew: false };

      return prevState;
    });
  }, []);

  const onMarkAll = useCallback((): void => {
    setNotifications((prevState): TNotification[] => prevState.map((item) => ({ ...item, isNew: false })));
  }, []);

  const transformNotification = useCallback((data: any): TNotification[] => {
    const createdAt = data?.storyAdded?.created_at;
    const images = data?.storyAdded?.images;
    const image = images?.[0];
    const createdNews = data?.storyAdded?.createdNews;
    const title = createdNews?.info?.title;
    const hash = data?.storyAdded?.hash;
    const url = `/${i18n.language}/stories/${createdNews?.info?.slug}-${hash}`;

    return title && hash ? [{ isNew: true, url, hash, title, image, createdAt }] : [];
  }, []);

  useEffect(() => {
    if (canUseDom) {
      const match = window.document.title?.match(REGEX_TITLE_COUNTER);
      const title = match
        ? window.document.title.slice(match?.[0].length + 1, window.document.title.length)
        : window.document.title;

      window.document.title = counter > 0 ? `(${counter}) ${title}` : title;
    }
  }, [counter]);

  useEffect(() => {
    if (data) {
      setNotifications((prevState): TNotification[] => [...transformNotification(data), ...prevState]);
      setCounter((prevState) => prevState + 1);
      play?.();
    }
  }, [data, play, transformNotification]);

  return {
    isPulsing: notifications.some((item) => item.isNew),
    notificationsRef,
    notifications,
    onMarkAll,
    onClick,
    onOpen,
  };
}
