import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { renderRoutes } from 'react-router-config';
import { useHistory, Switch } from 'react-router-dom';
import ga4 from 'react-ga4';

function Main({ routes }: any): React.ReactElement {
  const history = useHistory();

  useEffect(() => {
    ga4.initialize('G-F0M0D9TWHP');
  }, []);

  useEffect(() => {
    ga4.send({ hitType: 'pageview', page: history.location.pathname });
  }, [history.location]);

  return (
    <Flex
      as="main"
      position="relative"
      alignItems="start"
      justifyContent="space-between"
      transition="all 300ms"
      willChange="transform"
      p="0 1rem 0 1rem"
      w="100%"
    >
      <Switch>{renderRoutes(routes)}</Switch>
    </Flex>
  );
}

export default Main;
