import React from 'react';
import { FiBell } from 'react-icons/fi';
import ViewportList from 'react-viewport-list';
import { NavLink as RouterNavLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Button,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  LinkOverlay,
  keyframes,
} from '@chakra-ui/react';
import i18n from 'i18next';

import { getImg, hexToRgba } from '../../utils';
import Date from '../Date';
import { useNotifications } from './useNotifications';

const spin = keyframes`
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
`;

function Notifications() {
  const { isPulsing, notificationsRef, notifications, onClick, onMarkAll, onOpen } = useNotifications();

  function renderNotifications() {
    if (notifications.length === 0) {
      return (
        <Flex
          fontSize="12px"
          fontWeight="300"
          color="var(--colors-menuTitle)"
          alignItems="flex-start"
          userSelect="none"
          p="1rem"
        >
          {i18n.t('header.notifications.empty')}
        </Flex>
      );
    }

    return (
      <Flex
        ref={notificationsRef}
        flexDirection="column"
        willChange="transform"
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            width: '0.4rem',
          },
          '&::-webkit-scrollbar-track': {
            width: '0.4rem',
          },
          '&::-webkit-scrollbar-thumb': {
            background: hexToRgba('#000000', 0.2),
            borderRadius: '1rem',
          },
        }}
      >
        <ViewportList viewportRef={notificationsRef} items={notifications} itemMinSize={68}>
          {({ isNew, url, image, title, hash, createdAt }) => (
            <MenuItem
              key={hash}
              data-hash={hash}
              fontSize="12px"
              position="relative"
              alignItems="flex-start"
              background="var(--colors-menuBackground)"
              userSelect="none"
              p="1rem"
              _focus={{
                background: 'transparent',
              }}
              _active={{
                background: 'transparent',
              }}
              _hover={{
                background: 'transparent',
              }}
              onClick={onClick}
            >
              {image && (
                <Image
                  w="32px"
                  mr="12px"
                  boxSize="2rem"
                  borderRadius="full"
                  src={getImg(image?.name, { hash: image?.hash, created_at: image?.created_at }, 'original')}
                  alt={title}
                />
              )}
              {isNew && (
                <Box
                  position="absolute"
                  top="1.3rem"
                  left={image ? '2.7rem' : '1rem'}
                  opacity="0"
                  width="8px"
                  height="8px"
                  borderRadius="full"
                  backgroundColor="var(--colors-pulse)"
                  animation={`${spin} infinite 1.5s ease-out`}
                />
              )}
              <Flex flexDirection="column" ml={image ? '0' : '1rem'}>
                <LinkOverlay as={RouterNavLink} exact to={url} fontWeight="300" color="var(--colors-menuTitle)">
                  {title}
                </LinkOverlay>
                <Date createdAt={createdAt} fontWeight="300" color="var(--colors-menuDate)" />
              </Flex>
            </MenuItem>
          )}
        </ViewportList>
      </Flex>
    );
  }

  return (
    <Menu isLazy placement="bottom-end" onOpen={onOpen}>
      <MenuButton
        as={IconButton}
        isRound
        size="sm"
        variant="ghost"
        aria-label="Notifications"
        color="var(--colors-button)"
        icon={
          <>
            <FiBell size="20px" />
            {isPulsing && (
              <Box
                position="absolute"
                top="0"
                right="0"
                opacity="0"
                width="8px"
                height="8px"
                borderRadius="full"
                backgroundColor="var(--colors-pulse)"
                animation={`${spin} infinite 1.5s ease-out`}
              />
            )}
          </>
        }
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          background: 'var(--colors-buttonBackground)',
        }}
        _hover={{
          background: 'var(--colors-buttonBackground)',
        }}
      />
      <MenuList
        borderColor="var(--colors-menu)"
        backgroundColor="var(--colors-menuBackground)"
        boxShadow="0 5px 16px rgb(0 0 0 / 5%)"
        p="0"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'calc(100vw - 2rem)',
          maxHeight: 'calc(100vh - 4rem)',
          '@media (min-width: 768px)': {
            width: '26rem',
            maxHeight: '26rem',
          },
        }}
      >
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          borderBottom=".01rem solid var(--colors-menuDivider)"
          userSelect="none"
          p="1rem"
        >
          <Heading
            as="h6"
            color="var(--colors-menuHeader)"
            fontSize="11px"
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="1px"
          >
            {i18n.t('header.notifications.title')}
          </Heading>
          <Button
            variant="link"
            fontSize="11px"
            fontWeight="500"
            textTransform="uppercase"
            color="var(--colors-menuHeader)"
            isDisabled={!isPulsing}
            onClick={onMarkAll}
            _focus={{
              boxShadow: 'none',
            }}
            _hover={{
              textDecoration: 'none',
            }}
          >
            {i18n.t('header.notifications.markAll')}
          </Button>
        </Flex>
        {renderNotifications()}
      </MenuList>
    </Menu>
  );
}

export default Notifications;
