import React from 'react';
import { Flex, Box, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import i18n from 'i18next';

function Logo(): React.ReactElement {
  return (
    <Flex
      position="absolute"
      alignItems="center"
      justifyContent="center"
      userSelect="none"
      lineHeight="1"
      right="0"
      left="0"
    >
      <Link
        as={RouterLink}
        to={`/${i18n.language}/`}
        color="var(--colors-logo)"
        textDecoration="none"
        textAlign="center"
        alignItems="center"
        fontWeight="900"
        fontSize="2rem"
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          textDecoration: 'none',
        }}
      >
        {i18n.t('header.logo.news')}
        <Box as="small" display="block" fontWeight="400" fontSize="0.75rem" marginTop="0.1rem">
          news.24.md
        </Box>
      </Link>
    </Flex>
  );
}

export default Logo;
