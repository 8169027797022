import React from 'react';
import i18n from 'i18next';
import { FiFacebook, FiTwitter, FiSend } from 'react-icons/fi';

const socialsData = [
  {
    id: 'facebook',
    icon: <FiFacebook size="20px" />,
    label: i18n.t('main.share.facebook'),
    href: 'https://www.facebook.com/news24moldova',
  },
  {
    id: 'twitter',
    icon: <FiTwitter size="20px" />,
    label: i18n.t('main.share.twitter'),
    href: 'https://twitter.com/news24moldova',
  },
  {
    id: 'telegram',
    icon: <FiSend size="20px" />,
    label: i18n.t('main.share.telegram'),
    href: 'https://t.me/news24moldova',
  },
];

export default socialsData;
