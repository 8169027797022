import React from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';
import { IconButton, useColorMode, useColorModeValue } from '@chakra-ui/react';

function ColorMode(): React.ReactElement {
  const { toggleColorMode } = useColorMode();
  const SwitchIcon = useColorModeValue(FiSun, FiMoon);
  const text = useColorModeValue('dark', 'light');

  return (
    <IconButton
      isRound
      size="sm"
      fontSize="lg"
      variant="ghost"
      aria-label={`Switch to ${text} mode`}
      icon={<SwitchIcon size="20px" color="var(--colors-switcher)" fill="var(--colors-switcher)" />}
      _focus={{
        boxShadow: 'none',
      }}
      _active={{
        background: 'var(--colors-buttonBackground)',
      }}
      _hover={{
        background: 'var(--colors-buttonBackground)',
      }}
      onClick={toggleColorMode}
    />
  );
}

export default ColorMode;
