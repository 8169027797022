import React from 'react';
import { Flex } from '@chakra-ui/react';

import { checkI18n } from '../../i18n';
import Header from './Header';
import Main from './Main';

function Root(props: any): React.ReactElement {
  return (
    <Flex
      direction="column"
      m={[0, 'auto', 0, 'auto']}
      maxW={['100%', '100%', '100%', '1040px', '1200px']}
      minH="100vh"
    >
      <Header />
      <Main routes={props.route.routes} />
    </Flex>
  );
}

export default checkI18n(Root);
