import React from 'react';
import { Flex } from '@chakra-ui/react';

import Notifications from '../../components/Notifications';
import ColorMode from '../../components/ColorMode';
import Socials from '../../components/Socials';
import Logo from '../../components/Logo';

function Header(): React.ReactElement {
  return (
    <Flex
      as="header"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
      p="0 1rem 0 1rem"
      mb="10px"
      h="60px"
      w="100%"
    >
      <Socials />
      <Logo />
      <Flex>
        <ColorMode />
        <Notifications />
      </Flex>
    </Flex>
  );
}

export default Header;
