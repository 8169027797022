import React from 'react';
import { Flex, IconButton } from '@chakra-ui/react';

import socialsData from '../../data/socials';

function Socials(): React.ReactElement {
  return (
    <Flex>
      {socialsData.map(({ id, label, icon, href }) => (
        <IconButton
          key={id}
          isRound
          size="sm"
          zIndex="1"
          fontSize="lg"
          variant="ghost"
          color="var(--colors-button)"
          aria-label={label}
          icon={icon}
          _focus={{
            boxShadow: 'none',
          }}
          _active={{
            background: 'var(--colors-buttonBackground)',
          }}
          _hover={{
            background: 'var(--colors-buttonBackground)',
          }}
          onClick={() => window.open(href, '_blank')}
        />
      ))}
    </Flex>
  );
}

export default Socials;
