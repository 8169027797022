import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function RedirectWithStatus({ status, from, to }: any): React.ReactElement {
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) {
          staticContext.status = status;
        }

        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

export default RedirectWithStatus;
