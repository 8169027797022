import React from 'react';
import i18n from 'i18next';
import parser from 'accept-language-parser';
import { FacebookProvider } from 'react-facebook';

import RedirectWithStatus from './components/RedirectWithStatus';

export function checkI18n(WrappedContainer: any) {
  return (props: any) => {
    const lng = parser.pick(['ro', 'ru', 'en'], props?.location?.pathname, { loose: true });

    if (lng !== i18n?.language) {
      const lngToSet = lng || 'ro';
      i18n.changeLanguage(lngToSet);

      return <RedirectWithStatus status={302} from={props?.location?.pathname} to={'/' + lngToSet + '/'} />;
    }

    return (
      <FacebookProvider appId="628502527625611" language={i18n?.language + '_' + i18n?.language.toUpperCase()}>
        <WrappedContainer {...props} />
      </FacebookProvider>
    );
  };
}
